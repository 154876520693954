// Components
import iconImage from "src/app/assets/logo.png";
// Styles
import { ThemeProvider, createStyles, createTheme, makeStyles } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D13E3A",
    },
    secondary: {
      main: "#44599e",
    },
    text: {
      primary: "#201d1e",
    },
  },
});

const bannerText: any = {
  style: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "15px",
    fontFamily: "Georgia, serif",
    background: "#201d1e",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "50px",
  },
};

export default function Home() {
    const useStyles = makeStyles((theme: any) =>
        createStyles({
          background: {
            height: '100vh',
            margin: '0 36px',
            display: 'flex',
            justifyContent: 'center',
            backgroundSize: "100% auto",
            backgroundPosition: "center",
            background: 'no-repeat',
            backgroundImage: `url(${iconImage})`,
          }
        })
    );
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
          <div className={classes.background}>
          </div>
        </ThemeProvider>
    )
}