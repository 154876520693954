
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const navBar: any = {
    cursor: 'pointer',
    marginRight: '24px',
}

export default function Nav() {
    const navigation = useNavigate();

    const handleNavChange = (navigate: string) => {
        navigation(navigate);
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" style={navBar} onClick={() => handleNavChange('/')}>
                        Home
                    </Typography>
                    <Typography variant="h6" component="div" style={navBar} onClick={() => handleNavChange('/terms')}>
                        Terms & Conditions HVAC
                    </Typography>
                    <Typography variant="h6" component="div" style={navBar} onClick={() => handleNavChange('/well-terms')}>
                        Terms & Conditions Well
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    )
}