import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";

// Pages
import Nav from "src/app/components/Nav";
import Home from "src/app/pages/Home";
import Terms from "src/app/pages/Terms";
import WellTerms from "src/app/pages/WellTerms";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D13E3A",
    },
    secondary: {
      main: "#44599e",
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/well-terms" element={<WellTerms />} />
      </Routes>
    </ThemeProvider>
  );
}
