import { environment } from "src/variables";
// Styles
import { Wrapper } from "src/app/App.styles";
import { createStyles, createTheme, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import Header from "src/app/components/Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#D13E3A",
    },
    secondary: {
      main: "#44599e",
    },
  },
});

const banner: any = {
  fontFamily: "Georgia, serif",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  fontSize: "50px",
  background: "linear-gradient(to right," + theme.palette.primary.main + ", " + theme.palette.secondary.main + ")"
};

const subText: any = {
  section: {
    margin: "24px 0",
  },
  header: {
    fontWeight: "bold",
  },
  text: {
    textAlign: "left",
    fontFamily: "Georgia, serif",
    fontSize: "24px",
    margin: "5px 0",
  },
};

let terms: string[][] = [];
terms[0] = ["Terms & Conditions for Well Drilling Services."];
terms[1] = ["Please note our terms and conditions below:"];
terms[2] = [
  "1. Credit ",
  "terms are strictly a maximum of thirty (30) days, should we not receive payment or come to an agreement on payment unfortunately we shall have no choice but to decommission the borehole."
];
terms[3] = [
  "2. Payment Terms. ",
  "Customer shall pay Seller a deposit in the amount of 65% of the total proposal. An additional 25% will be due when the work is 50% completed. Final payment shall be due after the work described in the Installation Proposal is substantially completed."
];
terms[4] = [
  "3. Ownership ",
  "of all goods supplied shall remain the property of Mohave Service Well Drilling until all sums have been paid in full."
];
terms[5] = [
  "4. Zoning and Permits. ",
  "Customer agrees to timely furnish all information necessary to secure plans and permits for the work called for under this Agreement, and Customer warrants the work contracted for to be in compliance with applicable zoning, classification and building codes. Any costs for work not in the Estimate but required by lawful authorities to bring the work into compliance with applicable code shall be the responsibility of the Customer. Seller assumes no responsibility for violation of zoning rules/laws."
];
terms[6] = [
  "5. Change Orders. ",
  "During the progress of the work under this Agreement, if Customer should order extra work not specified in the Agreement, Seller may require such extra work to be considered an agreement separate and aside from this Agreement and may require payment for said extra work in advance."
];
terms[7] = [
  "6. Work Schedule. ",
  "Work shall be completed within a reasonable time. Performance of this Agreement is subject to labor strikes, fires, acts of war or terrorism, acts of God, adverse weather conditions not reasonably anticipated, unusual delays in transportation, Seller’s ability to obtain materials, and/or any cause beyond Seller’s control."
];
terms[8] = [
  "7. Substitutions. ",
  "Should Seller be unable to obtain any material(s) specified in the Agreement or any Change Order, Seller shall have the right at its sole discretion to substitute comparable materials and such substitution shall not affect the Contract Price."
];
terms[9] = [
  "8. Excess Materials. ",
  "Extra materials left over upon completion shall be deemed Seller’s property, and Seller may enter upon the Property’s premises to remove excess material(s) at all reasonable hours."
];
terms[10] = [
  "9. Quality and quantity ",
  "are beyond our control as ground conditions vary in different parts of the country due to geological factors. Filtration may be required in some wells to treat problems such as iron, lime manganese to name but a few. In the situation of a dry borehole (no water) we charge for materials used which include steel & pvc liners, fuel and labor costs. Where the driller has in their opinion reached a consistent water supply no discrepancies over depth drilled shall be discounted from the invoice total."
];
terms[11] = [
  "10. Added expense may be required if; ",
  "there is a loss of circulation while drilling, drilling through looses cobbles or loose formations, no available water at drill site, or unforeseeable weather conditions that causes damage to the borehole."
];
terms[12] = [
  "11. We at Mohave Service well Drilling ",
  "shall not accept any liability for any field, lawn or driveway damage, entrances ie fencing, gates, walls etc , drains or buried services, dirt  from the drilling process or movement of plant equipment to and from your property. Disposal of arisings (soil), fluids from the drilling process is the responsibility of the client.<br>"+
  "Should you need any advice or have any discrepancies on your newly drilled well please contact us rather than seek advice from others as this often leads to false information been given.<br>"+
  "Any discrepancies shall be dealt with in a timely and professional manner."
];
terms[13] = [
  "12. Collections. ",
  "If amounts owing under this Agreement are not paid within thirty (30) days, Customer agrees to pay a late charge on any outstanding balance at two percent (2%) per month or twenty-four percent (34%) per annum on the unpaid amount calculated from the date payment was due. Customer will be deemed to have accepted Seller’s performance as complete under this Agreement unless Customer notified Seller in writing otherwise within thirty (30) days of substantial completion. Should Seller retain the assistance of a third party, including without limitation an attorney, to assist with collection of unpaid amounts due and owing, Customer agrees to pay Seller’s costs associated therewith including without limitation reasonable attorneys’ fees, court costs, and interest at the maximum legal rate.<br>"+
  "I understand if I have an unpaid balance to Mohave Service and do not make satisfactory payment arrangements, my account may be placed with an external collection agency. I will be responsible for reimbursement of any fees from the collection agency, including all costs and expenses incurred collecting my account, and possibly including reasonable attorney’s fees if so incurred during collection efforts.<br>"+
  "In order for Mohave Service or their designated external collection agency to service my account, and where not prohibited by applicable law, I agree that Mohave Service and the designated external collection agency are authorized to (i) contact me by telephone at the telephone number(s) I am providing, including wireless telephone numbers, which could result in charges to me, (ii) contact me by sending text messages (message and data rates may apply) or emails, using any email address I provide and (iii) methods of contact may include using pre-recorded/artificial voice message and/or use of an automatic dialing device, as applicable.<br>"+
  "Should you have any query on this matter please do not hesitate to contact us."
];


export default function App() {
  const useStyles = makeStyles((theme: any) =>
    createStyles({
      background: {
        color: "#FFFFF",
      },
      foreground: {
        minWidth: "100%",
        minHeight: "100%",
      },
      table: {
        maxWidth: 725,
      },
      text: {
        textAlign: "center",
        maxWidth: "715px",
        fontFamily: "Georgia, serif",
      },
      section: {
        padding: "0 48px",
      },
    })
  );

  const classes = useStyles();
  
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Header text={environment.siteTitle} style={banner} />
      </Wrapper>
      <Typography className={classes.section} component={'div'} variant={'body2'}>
        {terms.map((term: any[], index: number) => {
          return (
            <p key={'term_'+index} style={(subText.text, subText.section)}>
              {term.map((condition: string, index: number) => {
                return <span key={'condition_'+index} style={index === 0 || index === 2 ? subText.header : null} dangerouslySetInnerHTML={{ __html: condition}}></span>;
              })}
            </p>
          );
        })}
      </Typography>
    </ThemeProvider>
  );
}
