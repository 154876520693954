// Components
import { Typography, Divider } from "@material-ui/core";
// Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default function Header(props: any) {
  const display = props.text;
  const style = props.style;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      style,
      divide: {
        backgroundColor: style.fontColor,
      },
    })
  );
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.style}>{display}</Typography>
      {props.divider && <Divider className={classes.divide} />}
    </div>
  );
}
